import { ComponentType, lazy } from 'react';

import { UserStatusEnum } from 'types/subscriptions';

import { LINKS } from './constants';

// Landing public pages
// appPages/SuccessPayment
const BuilderPage = lazy(() => import('appPages/BuilderPage'));
const QRCodesPage = lazy(() => import('appPages/QRCodesPage'));
const AnalyticsPage = lazy(() => import('appPages/StatisticsPage'));
const PlansPage = lazy(() => import('appPages/BillingPage'));
const AccountPage = lazy(() => import('appPages/AccountPage'));
const QRCodesSignUp = lazy(() => import('appPages/QRCodesSignUp'));
const SubscriptionPricing = lazy(() => import('appPages/SubscriptionPricing'));
const UpgradeSubscription = lazy(() => import('appPages/UpgradeSubscription'));
const SubscriptionCard = lazy(() => import('appPages/SubscriptionCard'));
const SubscriptionCardFlow = lazy(() => import('appPages/SubscriptionCardFlow'));
const SubscriptionSuccess = lazy(() => import('appPages/SubscriptionSuccess'));
const CabinetContainer = lazy(() => import('containers/CabinetContainer'));
const InmobiLandingPage = lazy(() => import('containers/InmobiContainer'));
export type RouteObj = {
  path: string;
  element: ComponentType;
  exact?: boolean;
  children?: RouteObj[];
};

export const PROTECTED_ROUTES = [
  {
    path: LINKS.CABINET,
    element: CabinetContainer,
    children: [
      {
        path: LINKS.CABINET_ANALYTICS,
        element: AnalyticsPage,
        exact: true,
      },
      {
        path: LINKS.CABINET_QR_CODES,
        element: QRCodesPage,
        exact: true,
      },
      {
        path: LINKS.CABINET_ACCOUNT,
        element: AccountPage,
        exact: true,
      },
      {
        path: LINKS.CABINET_BILLING,
        element: PlansPage,
        exact: true,

        permissions: [
          UserStatusEnum.PREMIUM,
          UserStatusEnum.PREMIUM_GIFTED,
          UserStatusEnum.PREMIUM_CANCELLING,
          UserStatusEnum.PREMIUM_CANCELLED,
          UserStatusEnum.PREMIUM_IN_DEBT,
          UserStatusEnum.PREMIUM_UNPAID,
        ],
      },
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_PRICING,
    element: SubscriptionPricing,
    exact: true,
    permissions: [UserStatusEnum.FREEMIUM_NEW],
  },
  {
    path: LINKS.SUBSCRIPTION_PLANS,
    element: UpgradeSubscription,
    exact: true,
    redirectUrl: LINKS.CABINET_QR_CODES,
    permissions: [
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.UPGRADE,
    element: UpgradeSubscription,
    exact: true,
    redirectUrl: LINKS.CABINET_QR_CODES,
    permissions: [
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_CARD,
    element: SubscriptionCard,
    exact: true,
    permissions: [
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_SUCCESS,
    element: SubscriptionSuccess,
    exact: true,
    permissions: [
      UserStatusEnum.PREMIUM,
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_FLOW_CARD,
    element: SubscriptionCardFlow,
    exact: true,
    permissions: [
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_FLOW_SUCCESS,
    element: SubscriptionSuccess,
    exact: true,
    permissions: [
      UserStatusEnum.PREMIUM,
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_FREEMIUM_CARD,
    element: SubscriptionCardFlow,
    exact: true,
    permissions: [
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.SUBSCRIPTION_FREEMIUM_SUCCESS,
    element: SubscriptionSuccess,
    exact: true,
    permissions: [
      UserStatusEnum.PREMIUM,
      UserStatusEnum.FREEMIUM_NEW,
      UserStatusEnum.FREEMIUM_OLD,
      UserStatusEnum.PREMIUM_CANCELLED,
      UserStatusEnum.PREMIUM_UNPAID,
      UserStatusEnum.PREMIUM_IN_DEBT,
    ],
  },
  {
    path: LINKS.REGISTRATION_SUCCESS,
    element: SubscriptionSuccess,
    exact: true,
    permissions: [UserStatusEnum.FREEMIUM_NEW],
  },
];

export const PUBLIC_ROUTES = [
  {
    path: LINKS.BUILDER,
    element: BuilderPage,
    exact: true,
  },
  // THIS WILL DISSALLOW TRANSLATOR USER TO ACCESS SIGN_UP PAGE
  // IT IS A TEMPORARY SOLUTION AND WILL BE ADDRESSED AFTER THE RELEASE
  // {
  //   path: LINKS.QR_SIGN_UP,
  //   element: QRCodesSignUp,
  // },
  {
    path: LINKS.INMOBI,
    element: InmobiLandingPage,
    exact: true,
  },
];

export const PUBLIC_ONLY_ROUTES: { path: string; exact?: boolean; element: React.ReactNode }[] = [
  {
    path: LINKS.QR_SIGN_UP,
    element: QRCodesSignUp,
  },
];

export * from './constants';
